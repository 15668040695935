import { ValidationErrors } from '@angular/forms';
import { WlcmFormErrors } from '@wlcm/angular/forms';
import { WlcmPhoneValidationErrorType } from '@wlcm/angular/phone-input';
import { WlcmDateRangeValidation, WlcmDateValidation } from '@wlcm/angular/datepicker';
import { format } from 'date-fns';

export enum FormErrorType {
  MIN = 'min',
  MAX = 'max',
  EMAIL = 'email',
  EMAIL_CONFLICT = 'emailConflict',
  REQUIRED = 'required',
  MIN_LENGTH = 'minlength',
  MAX_LENGTH = 'maxlength',
  PATTERN = 'pattern',
  DONT_MATCH = 'dontMatch',
  UNSELECTED = 'unselected',
  SPECIFIC = 'specific',
  MATCHING = 'matching',
  MAT_DATEPICKER_PARSE = 'matDatepickerParse',
  MAT_DATEPICKER_MIN = 'matDatepickerMin',
  FILE_TOO_LARGE = 'fileTooLarge',
  FILE_HAS_WRONG_DIMENSIONS = 'fileWrongDimensions',
  FILE_HAS_WRONG_FORMAT = 'fileHasWrongFormat',
  MASK = 'mask',
  LINK = 'link',
  SPACES = 'spaces',
  NAME_ONLY_SPACES = 'nameOnlySpaces',
  NAME_START_SYMBOLS = 'nameStartSymbols',
  NAME_END_SYMBOLS = 'nameEndSymbols',
  NAME_CONTAIN_SYMBOLS = 'nameContainSymbols',
  NAME_TWO_WORDS = 'nameTwoWords',
  PASSWORD_PATTERN = 'passwordPattern',
  PASSWORDS_MISMATCH = 'passwordsMismatch',
  DATA_RANGE = 'dataRange',
  LOCATION_NAME_MIN = 'locationNameMin',
  LOCATION_NAME_MAX = 'locationNameMax',
  LOCATION_NAME_SYMBOLS = 'locationNameSymbols',
  LOCATION_ADDRESS_MAX = 'locationAddressMax',
}

export const FORM_ERRORS: WlcmFormErrors = {
  [FormErrorType.REQUIRED]: () => `Field is required`,
  [FormErrorType.EMAIL]: () => `Invalid email address`,
  [FormErrorType.EMAIL_CONFLICT]: () => `This email address has already signed up`,
  [FormErrorType.LINK]: () => `Please use the correct link. For example, https://link.com`,
  [FormErrorType.MIN]: ({ min }: ValidationErrors) => `At least ${min}`,
  [FormErrorType.MAX]: ({ max }: ValidationErrors) => `No more than ${max}`,
  [FormErrorType.MIN_LENGTH]: ({ requiredLength }: ValidationErrors) =>
    `Field cannot contain less than ${requiredLength} characters`,
  [FormErrorType.MAX_LENGTH]: ({ requiredLength }: ValidationErrors) =>
    `Field cannot contain more than ${requiredLength} characters`,
  [FormErrorType.UNSELECTED]: () => `Please choose from the available list`,
  [FormErrorType.PATTERN]: () => `The field does not match a pattern`,
  [FormErrorType.MATCHING]: () => `Doesn't match`,
  [FormErrorType.SPECIFIC]: (message) => `${message}`,
  [FormErrorType.MAT_DATEPICKER_PARSE]: () => `Invalid date format`,
  [FormErrorType.MAT_DATEPICKER_MIN]: () => `Invalid date`,
  [FormErrorType.FILE_TOO_LARGE]: () => `File is too big`,
  [FormErrorType.FILE_HAS_WRONG_DIMENSIONS]: () => `File has wrong dimensions`,
  [FormErrorType.FILE_HAS_WRONG_FORMAT]: () => `File has wrong format`,
  [FormErrorType.MASK]: () => `Invalid time format`,
  [FormErrorType.SPACES]: () => 'Spaces are not allowed',
  [FormErrorType.NAME_ONLY_SPACES]: () => 'Field is required S',
  [FormErrorType.NAME_START_SYMBOLS]: () => 'Full name cannot start with symbols',
  [FormErrorType.NAME_END_SYMBOLS]: () => 'Full name cannot end with symbols',
  [FormErrorType.NAME_CONTAIN_SYMBOLS]: () => 'Special symbols are not allowed',
  [FormErrorType.NAME_TWO_WORDS]: () => 'Full name is required',
  [WlcmDateValidation.Format]: ({ format }) => `Invalid date format. Please use the format ${format}`,
  [WlcmDateValidation.MinDate]: ({ minDate }) => `Date must be after ${format(minDate, 'MMM/dd/yyyy')}`,
  [WlcmDateValidation.MaxDate]: ({ maxDate }) => `Date must be before ${format(maxDate, 'MMM/dd/yyyy')}`,
  [WlcmDateRangeValidation.StartExceedsEnd]: () => `Start date cannot be after end date`,
  [WlcmPhoneValidationErrorType.InvalidPhoneNumber]: () => 'Invalid phone number',
  [FormErrorType.PASSWORD_PATTERN]: () =>
    'Invalid password. Your password must be at least 8 characters and include at least one special character, one uppercase letter, one lowercase letter, and one number.',
  [FormErrorType.PASSWORDS_MISMATCH]: () => `Passwords don't match`,
  [FormErrorType.DATA_RANGE]: () => `Please enter a number between 1 and 100`,
  [FormErrorType.LOCATION_NAME_MIN]: () => `Field cannot contain less than 2 characters`,
  [FormErrorType.LOCATION_NAME_MAX]: () => `Field cannot contain more than 25 characters`,
  [FormErrorType.LOCATION_NAME_SYMBOLS]: () => `Special symbols are not allowed`,
  [FormErrorType.LOCATION_ADDRESS_MAX]: () => `Field cannot contain more than 100 characters`,
};
